import React from 'react';
import { Box, Typography, Grid2, Select, MenuItem } from '@mui/material';

function PlayerSelection({ currentBatsmen, setCurrentBatsmen, currentBowler, setCurrentBowler, matchData }) {
 //const team = matchData.team1Batting ? matchData.team1 : matchData.team2;

  const battingTeam = matchData.team1Batting ? matchData.team1 : matchData.team2;
  const bowlingTeam = matchData.team1Batting ? matchData.team2 : matchData.team1;

  const handleBatsmanChange = (event, position) => {
    const newBatsmen = { ...currentBatsmen };
    newBatsmen[position] = event.target.value;
    setCurrentBatsmen(newBatsmen);
  };

  const handleBowlerChange = (event) => {
    setCurrentBowler(event.target.value);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">Select Players</Typography>
      <Grid2 container spacing={2}>
        <Grid2 item xs={4}>
          <Typography>Striker</Typography>
          <Select value={currentBatsmen.striker} onChange={(e) => handleBatsmanChange(e, 'striker')} fullWidth>
            {battingTeam.batting.map((player, index) => (
              <MenuItem key={index} value={player.name} disabled={player.out}>
                {player.name}
              </MenuItem>
            ))}
          </Select>
        </Grid2>
        <Grid2 item xs={4}>
          <Typography>Non-Striker</Typography>
          <Select value={currentBatsmen.nonStriker} onChange={(e) => handleBatsmanChange(e, 'nonStriker')} fullWidth>
            {battingTeam.batting.map((player, index) => (
              <MenuItem key={index} value={player.name} disabled={player.out || player.name === currentBatsmen.striker}>
                {player.name}
              </MenuItem>
            ))}
          </Select>
        </Grid2>
        <Grid2 item xs={4}>
          <Typography>Bowler</Typography>
          <Select value={currentBowler} onChange={handleBowlerChange} fullWidth>
            {bowlingTeam.bowling.map((player, index) => (
              <MenuItem key={index} value={player.name}>
                {player.name}
              </MenuItem>
            ))}
          </Select>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default PlayerSelection;
