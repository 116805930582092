import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function BallHistory({ ballHistory }) {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">Ball-by-Ball History</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Runs</TableCell>
              <TableCell>Wide</TableCell>
              <TableCell>No Ball</TableCell>
              <TableCell>Bye</TableCell>
              <TableCell>Leg Bye</TableCell>
              <TableCell>Wicket</TableCell>
              <TableCell>Dismissal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ballHistory.map((ball, index) => (
              <TableRow key={index}>
                <TableCell>{ball.runs}</TableCell>
                <TableCell>{ball.wide ? "Yes" : "No"}</TableCell>
                <TableCell>{ball.noBall ? "Yes" : "No"}</TableCell>
                <TableCell>{ball.bye ? "Yes" : "No"}</TableCell>
                <TableCell>{ball.legBye ? "Yes" : "No"}</TableCell>
                <TableCell>{ball.wicket ? "Yes" : "No"}</TableCell>
                <TableCell>{ball.dismissalType}</TableCell>
                {/* <TableCell>{ball.commentary}</TableCell>  Add this line to display commentary */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default BallHistory;
