import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, AppBar } from '@mui/material';
import LiveTab from './components/LiveTab';
import ScorecardTab from './components/ScorecardTab';
import LiveScoreDisplay from './components/LiveScoreDisplay';
import PlayerForm from './components/Players';

function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [team1Players, setTeam1Players] = useState([]);
  const [team2Players, setTeam2Players] = useState([]);

  // const fetchCommentary = async (eventData) => {
  //   try {
  //     const response = await fetch('http://localhost:5000/api/generate_commentary', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(eventData),
  //     });
  //     const data = await response.json();
  //     return data.commentary;
  //   } catch (error) {
  //     console.error("Error fetching commentary:", error);
  //     return '';
  //   }
  // };


  // Dummy team members
  // const team1Players = [
  //   { name: 'Player 1', runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' },
  //   { name: 'Player 2', runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' },
  //   { name: 'Player 3', runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' },
  //   // Add more players...
  // ];
  //
  // const team2Players = [
  //   { name: 'Player A', runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' },
  //   { name: 'Player B', runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' },
  //   { name: 'Player C', runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' },
  //   // Add more players...
  // ];

  const team1Bowlers = [
    { name: 'Bowler 1', overs: 0, maidens: 0, runs: 0, wickets: 0, economy: 0, wides: 0, noBalls: 0 },
    { name: 'Bowler 2', overs: 0, maidens: 0, runs: 0, wickets: 0, economy: 0, wides: 0, noBalls: 0 },
    // Add more bowlers...
  ];

  const team2Bowlers = [
    { name: 'Bowler A', overs: 0, maidens: 0, runs: 0, wickets: 0, economy: 0, wides: 0, noBalls: 0 },
    { name: 'Bowler B', overs: 0, maidens: 0, runs: 0, wickets: 0, economy: 0, wides: 0, noBalls: 0 },
    // Add more bowlers...
  ];

  const [matchData, setMatchData] = useState({
    currentRuns: 0,
    currentWickets: 0,
    currentOvers: 0,
    currentBalls: 0,
    team1Batting: true, // toggle between teams
    team1: {
      batting: team1Players,
      bowling: team1Bowlers,
      totalRuns: 0,
      totalWickets: 0,
      totalOvers: 0,
    },
    team2: {
      batting: team2Players,
      bowling: team2Bowlers,
      totalRuns: 0,
      totalWickets: 0,
      totalOvers: 0,
    },
    ballHistory: [],
  });

  const [currentBatsmen, setCurrentBatsmen] = useState({ striker: '', nonStriker: '' });
  const [currentBowler, setCurrentBowler] = useState('');

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Live Cricket Match
      </Typography>

      {/* Player Input Form */}
      <PlayerForm
          setTeam1Players={setTeam1Players}
          setTeam2Players={setTeam2Players}
          setMatchData={setMatchData}
          matchData={matchData}
      />

      {/* Live Score Display */}
      <LiveScoreDisplay matchData={matchData} />

      {/* Tabs for Live and Scorecard */}
      <AppBar position="static">
        <Tabs value={tabIndex}
              onChange={handleTabChange}
              aria-label="live and scorecard tabs"
              variant="fullWidth"
              textColor="inherit"  // Use this for customizing text color
              TabIndicatorProps={{
                sx: { backgroundColor: "#f50057" }  // Change active tab's indicator color
              }}
        >
          <Tab label="Live" />
          <Tab label="Scorecard" />
        </Tabs>
      </AppBar>

      {/* Tab Content */}
      {tabIndex === 0 && (
        <LiveTab
          matchData={matchData}
          setMatchData={setMatchData}
          currentBatsmen={currentBatsmen}
          setCurrentBatsmen={setCurrentBatsmen}
          currentBowler={currentBowler}
          setCurrentBowler={setCurrentBowler}
         // fetchCommentary={fetchCommentary}
        />
      )}
      {tabIndex === 1 && <ScorecardTab matchData={matchData} />}
    </Box>
  );
}

export default App;
