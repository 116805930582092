import React from 'react';
import { Box, Typography } from '@mui/material';

function LiveScoreDisplay({ matchData }) {
  const { currentRuns, currentWickets, currentOvers } = matchData;

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h5">
        Live Score: {currentRuns}/{currentWickets} in {currentOvers} overs
      </Typography>
    </Box>
  );
}

export default LiveScoreDisplay;
