import React, { useState, useEffect } from 'react';
import {Box, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem, Grid2} from '@mui/material';

const PlayerForm = ({ setTeam1Players, setTeam2Players, setMatchData, matchData }) => {
  const [playerInput, setPlayerInput] = useState({ team1: '', team2: '' });
  const [teamNames, setTeamNames] = useState({ team1Name: '', team2Name: '' });
  const [teamCaptains, setTeamCaptains] = useState({ team1Captain: '', team2Captain: '' });
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [umpire, setUmpire] = useState('');
    const [tossWinner, setTossWinner] = useState('');
    const [error, setError] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPlayerInput({ ...playerInput, [name]: value });
  };

  const handleTeamNameChange = (e) => {
    const { name, value } = e.target;
    setTeamNames({ ...teamNames, [name]: value });
  };

  const handleTeamCaptainChange = (e) => {
    const { name, value } = e.target;
    setTeamCaptains({ ...teamCaptains, [name]: value });
  };

    const handleTossWinnerChange = (e) => {
        setTossWinner(e.target.value);
    }

  useEffect(() => {
    const team1PlayersArray = playerInput.team1.split(',').map(name => name.trim());
    const team2PlayersArray = playerInput.team2.split(',').map(name => name.trim());

    if (team1PlayersArray.length === 11 && team2PlayersArray.length === 11) {
      setError('');
    }
  }, [playerInput]);

  const handleFormSubmit = () => {
    const team1PlayersArray = playerInput.team1.split(',').map(name => ({ name: name.trim(), runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' }));
    const team2PlayersArray = playerInput.team2.split(',').map(name => ({ name: name.trim(), runs: 0, balls: 0, fours: 0, sixes: 0, out: false, howOut: '' }));

    if (team1PlayersArray.length !== 11 || team2PlayersArray.length !== 11) {
      setError('Each team must have exactly 11 players.');
      return;
    }

    const team1BowlersArray = team1PlayersArray.map(player => ({ name: player.name, overs: 0, maidens: 0, runs: 0, wickets: 0, economy: 0, wides: 0, noBalls: 0 }));
    const team2BowlersArray = team2PlayersArray.map(player => ({ name: player.name, overs: 0, maidens: 0, runs: 0, wickets: 0, economy: 0, wides: 0, noBalls: 0 }));

    setTeam1Players(team1PlayersArray);
    setTeam2Players(team2PlayersArray);

    setMatchData({
      ...matchData,
      date,
      location, umpire,
        tossWinner,
      team1: {
        name: teamNames.team1Name,
        captain: teamCaptains.team1Captain,
        batting: team1PlayersArray,
        bowling: team1BowlersArray,
        totalRuns: 0,
        totalWickets: 0,
        totalOvers: 0,
      },
      team2: {
        name: teamNames.team2Name,
        captain: teamCaptains.team2Captain,
        batting: team2PlayersArray,
        bowling: team2BowlersArray,
        totalRuns: 0,
        totalWickets: 0,
        totalOvers: 0,
      },
    });

    setError('');
  };

    return (
        <Box component="form" sx={{ mb: 3 }}>
            <Grid2 container spacing={2}>
                <Grid2 item xs={6}>
                    <TextField
                        label="Date"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid2>
                <Grid2 item xs={6}>
                    <TextField
                        label="Location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        fullWidth
                    />
                </Grid2>
                <Grid2 item xs={6}>
                    <TextField
                        label="Umpire"
                        name="umpire"
                        value={umpire}
                        onChange={(e) => setUmpire(e.target.value)}
                        fullWidth
                    />
                </Grid2>
                <Box sx={{ width: '100%' }} />
                <Grid2 item xs={6}>
                    <TextField
                        label="Team 1 Name"
                        name="team1Name"
                        value={teamNames.team1Name}
                        onChange={handleTeamNameChange}
                        fullWidth
                    />
                </Grid2>
                <Grid2 item xs={6}>
                    <TextField
                        label="Team 1 Captain"
                        name="team1Captain"
                        value={teamCaptains.team1Captain}
                        onChange={handleTeamCaptainChange}
                        fullWidth
                    />
                </Grid2>
                <Grid2 item xs={6}>
                    <TextField
                        label="Team 1 Players (comma separated)"
                        name="team1"
                        value={playerInput.team1}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid2>
                <Box sx={{ width: '100%' }} />
                <Grid2 item xs={6}>
                    <TextField
                        label="Team 2 Name"
                        name="team2Name"
                        value={teamNames.team2Name}
                        onChange={handleTeamNameChange}
                        fullWidth
                    />
                </Grid2>
                <Grid2 item xs={6}>
                    <TextField
                        label="Team 2 Captain"
                        name="team2Captain"
                        value={teamCaptains.team2Captain}
                        onChange={handleTeamCaptainChange}
                        fullWidth
                    />
                </Grid2>
                <Grid2 item xs={6}>
                    <TextField
                        label="Team 2 Players (comma separated)"
                        name="team2"
                        value={playerInput.team2}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid2>
                <Box sx={{ width: '100%' }} />
                <Grid2 item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="toss-winner-label">Won Toss</InputLabel>
                        <Select
                            labelId="toss-winner-label"
                            value={tossWinner}
                            onChange={handleTossWinnerChange}
                            sx={{ minWidth: 200 }}
                        >
                            <MenuItem value={teamNames.team1Name}>{teamNames.team1Name}</MenuItem>
                            <MenuItem value={teamNames.team2Name}>{teamNames.team2Name}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
            </Grid2>
            {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
            <Button variant="contained" color="primary" onClick={handleFormSubmit} sx={{ mt: 2 }}>
                Set Teams
            </Button>
        </Box>
    );
};

export default PlayerForm;
