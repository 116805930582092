import React from 'react';
import {
  Box,
  Typography,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material';

function ScorecardTab({ matchData }) {
  const team1 = matchData.team1;
  const team2 = matchData.team2;

  // Function to render batting table for the team
  const renderBattingTable = (team) => (
    <TableContainer component={Paper} sx={{ mb: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Batsman</TableCell>
            <TableCell>Runs</TableCell>
            <TableCell>Balls</TableCell>
            <TableCell>4s</TableCell>
            <TableCell>6s</TableCell>
            <TableCell>Strike Rate</TableCell>
            <TableCell>How Out</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team.batting.map((player, index) => (
            <TableRow key={index}>
              <TableCell>{player.name}</TableCell>
              <TableCell>{player.runs}</TableCell>
              <TableCell>{player.balls}</TableCell>
              <TableCell>{player.fours}</TableCell>
              <TableCell>{player.sixes}</TableCell>
              <TableCell>{((player.runs / player.balls) * 100).toFixed(2)}</TableCell>
              <TableCell>{player.howOut}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Function to render bowling table for the team
  const renderBowlingTable = (team) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Bowler</TableCell>
            <TableCell>Overs</TableCell>
            <TableCell>Maidens</TableCell>
            <TableCell>Runs</TableCell>
            <TableCell>Wickets</TableCell>
            <TableCell>Economy</TableCell>
            <TableCell>Wides</TableCell>
            <TableCell>No Balls</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team.bowling.map((bowler, index) => (
            <TableRow key={index}>
              <TableCell>{bowler.name}</TableCell>
              <TableCell>{bowler.overs}</TableCell>
              <TableCell>{bowler.maidens}</TableCell>
              <TableCell>{bowler.runs}</TableCell>
              <TableCell>{bowler.wickets}</TableCell>
              <TableCell>{(bowler.runs / (bowler.overs || 1)).toFixed(2)}</TableCell>
              <TableCell>{bowler.wides}</TableCell>
              <TableCell>{bowler.noBalls}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Rendering both batting and bowling stats for Team 1 and Team 2
  const renderTeamStats = (teamBatting, teamBowling) => (
    <Grid2 container spacing={2}>
      <Grid2 item xs={6}>
        <Typography variant="h6" gutterBottom>Batting</Typography>
        {renderBattingTable(teamBatting)}
      </Grid2>
      <Grid2 item xs={6}>
        <Typography variant="h6" gutterBottom>Bowling</Typography>
        {renderBowlingTable(teamBowling)}
      </Grid2>
    </Grid2>
  );
  const generateCSV = () => {
    const battingHeaders = ['Batsman', 'Runs', 'Balls', '4s', '6s', 'Strike Rate', 'How Out'];
    const bowlingHeaders = ['Bowler', 'Overs', 'Maidens', 'Runs', 'Wickets', 'Economy', 'Wides', 'No Balls'];

    const team1BattingData = team1.batting.map(player => [
      player.name, player.runs, player.balls, player.fours, player.sixes, ((player.runs / player.balls) * 100).toFixed(2), player.howOut
    ]);
    const team2BattingData = team2.batting.map(player => [
      player.name, player.runs, player.balls, player.fours, player.sixes, ((player.runs / player.balls) * 100).toFixed(2), player.howOut
    ]);

    const team1BowlingData = team1.bowling.map(bowler => [
      bowler.name, bowler.overs, bowler.maidens, bowler.runs, bowler.wickets, (bowler.runs / (bowler.overs || 1)).toFixed(2), bowler.wides, bowler.noBalls
    ]);
    const team2BowlingData = team2.bowling.map(bowler => [
      bowler.name, bowler.overs, bowler.maidens, bowler.runs, bowler.wickets, (bowler.runs / (bowler.overs || 1)).toFixed(2), bowler.wides, bowler.noBalls
    ]);

    const csvContent = [
      `Date: ${matchData.date}`,
      `Location: ${matchData.location}`,
      `Umpire: ${matchData.umpire}`,
        `Toss Winner: ${matchData.tossWinner}`,
      `Team 1: ${team1.name} vs Team 2: ${team2.name}`,
      `Team ${team1.name} Captain: ${team1.captain}`,
      `Team ${team1.name} Batting`,
      battingHeaders.join(','),
      ...team1BattingData.map(row => row.join(',')),
      '',
      `Team ${team1.name} Bowling`,
      bowlingHeaders.join(','),
      ...team1BowlingData.map(row => row.join(',')),
      '',
      `Team ${team2.name} Captain: ${team2.captain}`,
      `Team ${team2.name} Batting`,
      battingHeaders.join(','),
      ...team2BattingData.map(row => row.join(',')),
      '',
      `Team ${team2.name} Bowling`,
      bowlingHeaders.join(','),
      ...team2BowlingData.map(row => row.join(','))
    ].join('\n');

    return csvContent;
  };

  const downloadCSV = () => {
    const csvContent = generateCSV();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'scorecard.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box>
      <Typography variant="h5" gutterBottom>Date: {matchData.date}</Typography>
      <Typography variant="h5" gutterBottom>Location: {matchData.location}</Typography>
      <Typography variant="h5" gutterBottom>Umpire: {matchData.umpire}</Typography>
      <Typography variant="h5" gutterBottom>Toss Winner: {matchData.tossWinner}</Typography>
      <Typography variant="h5" gutterBottom>Team {team1.name}, Captain: {team1.captain}</Typography>
      {renderTeamStats(team1, team2)} {/* Team 1's batting and Team 2's bowling */}

      <Typography variant="h5" gutterBottom>Team {team2.name}, Captain: {team2.captain}</Typography>
      {renderTeamStats(team2, team1)} {/* Team 2's batting and Team 1's bowling */}

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
        <Button variant="contained" color="primary" onClick={downloadCSV}>
          Download Scorecard
        </Button>
      </Box>
    </Box>
  );
}

export default ScorecardTab;
