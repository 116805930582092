import React, { useState } from 'react';
import { Box, Button, TextField, Select, MenuItem, Grid2, Typography } from '@mui/material';

function BallEventForm({ onBallEvent }) {
  const [runs, setRuns] = useState(0);
  const [wide, setWide] = useState(false);
  const [noBall, setNoBall] = useState(false);
  const [bye, setBye] = useState(false);
  const [legBye, setLegBye] = useState(false);
  const [wicket, setWicket] = useState(false);
  const [dismissalType, setDismissalType] = useState('');

  const handleSubmit = () => {
    const eventData = {
      runs: parseInt(runs),
      wide,
      noBall,
      bye,
      legBye,
      wicket,
      dismissalType,
    };
    onBallEvent(eventData); // Pass the event data to the parent component
    // Reset the form after submission
    setRuns(0);
    setWide(false);
    setNoBall(false);
    setBye(false);
    setLegBye(false);
    setWicket(false);
    setDismissalType('');
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">Record Ball Event</Typography>
      <Grid2 container spacing={2}>
        <Grid2 item xs={3}>
          <TextField
            label="Runs"
            type="number"
            value={runs}
            onChange={(e) => setRuns(e.target.value)}
            fullWidth
          />
        </Grid2>
        <Grid2 item xs={2}>
          <Button variant={wide ? "contained" : "outlined"} onClick={() => setWide(!wide)} fullWidth>
            Wide
          </Button>
        </Grid2>
        <Grid2 item xs={2}>
          <Button variant={noBall ? "contained" : "outlined"} onClick={() => setNoBall(!noBall)} fullWidth>
            No Ball
          </Button>
        </Grid2>
        <Grid2 item xs={2}>
          <Button variant={bye ? "contained" : "outlined"} onClick={() => setBye(!bye)} fullWidth>
            Bye
          </Button>
        </Grid2>
        <Grid2 item xs={2}>
          <Button variant={legBye ? "contained" : "outlined"} onClick={() => setLegBye(!legBye)} fullWidth>
            Leg Bye
          </Button>
        </Grid2>
        <Grid2 item xs={12}>
          <Button
            variant={wicket ? "contained" : "outlined"}
            onClick={() => setWicket(!wicket)}
            fullWidth
          >
            Wicket
          </Button>
        </Grid2>
        {wicket && (
          <Grid2 item xs={12}>
            <Select
              value={dismissalType}
              onChange={(e) => setDismissalType(e.target.value)}
              fullWidth
            >
              <MenuItem value="Bowled">Bowled</MenuItem>
              <MenuItem value="Caught">Caught</MenuItem>
              <MenuItem value="LBW">LBW</MenuItem>
              <MenuItem value="Run Out">Run Out</MenuItem>
            </Select>
          </Grid2>
        )}
        <Grid2 item xs={12}>
          <Button variant="contained" onClick={handleSubmit} fullWidth>
            Submit Ball Event
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default BallEventForm;
