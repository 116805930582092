import React from 'react';
import { Box } from '@mui/material';
import BallEventForm from './BallEventForm';
import PlayerSelection from './PlayerSelection';
import BallHistory from './BallHistory';

function LiveTab({ matchData, setMatchData, currentBatsmen, setCurrentBatsmen, currentBowler, setCurrentBowler }) {
  const handleBallEvent =async (eventData) => {
    console.log("Ball event handler triggered");
    let newMatchData = { ...matchData };
    const battingTeam = newMatchData.team1Batting ? newMatchData.team1 : newMatchData.team2;
    const bowlingTeam = newMatchData.team1Batting ? newMatchData.team2 : newMatchData.team1;

    if (!currentBatsmen.striker || !currentBowler) {
      alert('Please select both batsmen and bowler before submitting an event.');
      return;
    }

    // Update batsmen's runs, balls, and 4s/6s if applicable
    const strikerIndex = battingTeam.batting.findIndex(player => player.name === currentBatsmen.striker);
    if (strikerIndex >= 0 && !eventData.wide && !eventData.noBall) {
      battingTeam.batting[strikerIndex].runs += eventData.runs || 0;
      battingTeam.batting[strikerIndex].balls += 1;

      if (eventData.runs === 4) {
        battingTeam.batting[strikerIndex].fours += 1;
      } else if (eventData.runs === 6) {
        battingTeam.batting[strikerIndex].sixes += 1;
      }
    }


    // If wicket falls, update "How Out" status
    if (eventData.wicket) {
      battingTeam.batting[strikerIndex].out = true;
      battingTeam.batting[strikerIndex].howOut = eventData.dismissalType;  // Set the "How Out" status
        newMatchData.currentWickets += 1; // Update current wickets
    }

    // If the ball is wide or no ball, add 1 extra run to the score
    let extraRuns = 0;
    if (eventData.wide || eventData.noBall) {
      extraRuns += 1; // Every wide or no-ball gives 1 run
      newMatchData.currentRuns += 1; // Update total score
    }

    // Add any runs from wide or no-ball
    newMatchData.currentRuns += eventData.runs || 0;

    // Update bowler stats
    const bowlerIndex = bowlingTeam.bowling.findIndex(player => player.name === currentBowler);
    if (bowlerIndex >= 0) {
      const bowler = bowlingTeam.bowling[bowlerIndex];

      // Update bowler stats: Runs, Wickets, Wides, No-Balls
      bowler.runs += eventData.runs || 0;
      if (eventData.wicket) {
        bowler.wickets += 1;
      }
      if (eventData.wide) {
        bowler.wides += 1;
      }
      if (eventData.noBall) {
        bowler.noBalls += 1;
      }

      // Update overs for legitimate deliveries (exclude wides and no-balls)
      if (!eventData.wide && !eventData.noBall) {
        const totalBallsBowled = (bowler.overs * 6) + 1; // Increment ball count
        bowler.overs = totalBallsBowled / 6; // Convert to fractional overs
      }

      // Handle maidens: Check if it's the 6th legitimate ball in an over
      if (bowler.overs % 1 === 0) { // Full over completed (e.g., 1.0, 2.0)
        const ballsThisOver = 6; // Full over is completed

        const runsThisOver = eventData.runsThisOver || 0; // Track runs given in this over

        if (runsThisOver === 0 && bowler.wides === 0 && bowler.noBalls === 0) {
          bowler.maidens += 1; // Mark this over as a maiden if no runs, wides, or no-balls
        }

        // Reset runs, wides, and no-balls counters for the next over
        eventData.runsThisOver = 0;
      }
    }



    // Update overs and balls count
    if (!eventData.wide && !eventData.noBall) { // Wides and no-balls don't count toward the over
      newMatchData.currentBalls += 1;
      if (newMatchData.currentBalls >= 6) {
        newMatchData.currentBalls = 0;
        newMatchData.currentOvers += 1;
        alert('Please select a new bowler for the next over.');
      }
    }

    // Rotate strike on odd number of runs
    if (eventData.runs % 2 === 1 && !eventData.wide && !eventData.noBall) {
      const tempStriker = currentBatsmen.striker;
      setCurrentBatsmen({ striker: currentBatsmen.nonStriker, nonStriker: tempStriker });
    }

      // Fetch commentary from the backend
    // const commentary = await fetchCommentary(eventData);
    // eventData.commentary = commentary;  // Add commentary to the event data

    // Add the ball event to the history (most recent ball first)
    newMatchData.ballHistory.unshift(eventData);

    // Update the match data state
    setMatchData(newMatchData);
  };

  return (
    <Box>
      <PlayerSelection
        currentBatsmen={currentBatsmen}
        setCurrentBatsmen={setCurrentBatsmen}
        currentBowler={currentBowler}
        setCurrentBowler={setCurrentBowler}
        matchData={matchData}
      />

      <BallEventForm onBallEvent={handleBallEvent} />

      <BallHistory ballHistory={matchData.ballHistory} />
    </Box>
  );
}

export default LiveTab;
